//Components
@import 'assets/styles/components/alerts.scss';
@import 'assets/styles/components/buttons.scss';
@import 'assets/styles/components/card.scss';
@import 'assets/styles/components/forms.scss';
@import 'assets/styles/components/grid-filters.scss';
@import 'assets/styles/components/header.scss';
@import 'assets/styles/components/links.scss';
@import 'assets/styles/components/menu.scss';
@import 'assets/styles/components/modals-dialogs.scss';
@import 'assets/styles/components/navlist.scss';
@import 'assets/styles/components/paginator.scss';
@import 'assets/styles/components/sidebar.scss';
@import 'assets/styles/components/stepper.scss';
@import 'assets/styles/components/tabs.scss';
@import 'assets/styles/components/title-w-line.scss';

//Layout
@import 'assets/styles/layout/containers.scss';
@import 'assets/styles/layout/grid.scss';

//Utilities
@import 'assets/styles/utilities/spacing.scss';
@import 'assets/styles/utilities/text.scss';

//Others
@import 'assets/styles/_variables.scss';
@import 'assets/styles/icons.scss';

html,
body {
  height: 100%;
  background-color: var(--light-grey);
  margin: 0;
}

// Content Header Area
.section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 30px 0;
  gap: 10px;
  .mat-mdc-button.button-w-icon-suffix {
    flex-direction: row-reverse;
    .mat-icon {
      margin: 0 0 0 5px;
    }
  }
}

//Logo Transportes
.wrap-logo-transportes {
  align-self: center;
  margin: 0 auto 12px auto;
}

.logo-transportes {
  width: 165.4px;
  height: 30px;
}

// Spinners
.spinner-fullscreen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: var(--background);
  .mat-mdc-progress-spinner {
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 25px);
    --mdc-circular-progress-active-indicator-color: var(--light-blue);
  }
}

.spinner-fullscreen-grid {
  width: 100%;
  height: calc(100vh - 140px);
  z-index: 9999;
  .mat-mdc-progress-spinner {
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 25px);
    --mdc-circular-progress-active-indicator-color: var(--light-blue);
  }
}

.mat-mdc-progress-spinner {
  // Use it with [color] tag in <mat-spinner>
  &.mat-blue svg {
    stroke: var(--blue);
  }
  &.mat-light-blue svg {
    stroke: var(--light-blue);
  }
}

// Timepicker
.timepicker-backdrop-overlay {
  z-index: 1000 !important;
}

.timepicker-overlay {
  z-index: 1000 !important;
}

.ngx-timepicker-control__arrows {
  top: -5px !important;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.button-link {
  background: none;
  border: none;
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
}

.button-link:hover {
  color: #0056b3;
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.custom-filter-button {
  border-radius: unset !important;
}

//scroll
.mdc-dialog__surface,
.modal-dialog-wrap {
  overflow: auto !important;
}
.mdc-dialog__surface::-webkit-scrollbar,
.modal-dialog-wrap::-webkit-scrollbar {
  width: 5px !important;
}

.mdc-dialog__surface::-webkit-scrollbar-thumb,
.modal-dialog-wrap::-webkit-scrollbar-thumb {
  background-color: var(--blue) !important;
  border-radius: 10px !important;
}

.mdc-dialog__surface::-webkit-scrollbar-track,
.modal-dialog-wrap::-webkit-scrollbar-track {
  background: transparent !important;
}

mat-expansion-panel.delivery-mouth-panel div.mat-expansion-panel-body {
  padding: 0 !important;
}
.mat-expansion-panel-header {
  padding: 4px 12px !important;
  span.mat-content {
    flex-direction: column !important;
  }
}
mat-stepper.single-delivery-stepper .mat-horizontal-content-container {
  padding: 0 !important;
}

app-single-delivery-modal .modal-dialog-wrap {
  padding: 10px !important;
}

.mat-button-toggle-button {
  border: 0;
  background: none;
  color: inherit;
  padding: 0;
  margin: 0;
  font: inherit;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.mat-button-toggle-label-content {
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  padding: 0 16px;
  line-height: var(--mat-legacy-button-toggle-height);
  position: relative;
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  border-radius: var(--mat-legacy-button-toggle-shape);
}

.mat-button-toggle {
  white-space: nowrap;
  position: relative;
  color: var(--mat-legacy-button-toggle-text-color);
  font-family: var(--mat-legacy-button-toggle-label-text-font);
  font-size: var(--mat-legacy-button-toggle-label-text-size);
  line-height: var(--mat-legacy-button-toggle-label-text-line-height);
  font-weight: var(--mat-legacy-button-toggle-label-text-weight);
  letter-spacing: var(--mat-legacy-button-toggle-label-text-tracking);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(
    --mat-legacy-button-toggle-selected-state-text-color
  );
}

.mat-button-toggle-checked {
  color: #ffffff !important;
  background-color: #3c81bb !important;
}

.infinite-scroll {
  overflow-y: auto;
  max-height: 100% !important;
}

.infinite-scroll::-webkit-scrollbar {
  width: 5px;
}

.infinite-scroll::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 10px;
}

.infinite-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.spinner-container {
  position: relative;
  margin: auto;
}

.ngx-spinner {
  width: 106px;
  align-items: center;
  justify-content: center;
  vertical-align: bottom;
  bottom: 15px;
  position: absolute;
}

.mat-mdc-tab-body.mat-mdc-tab-body-active {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.mat-mdc-tab-body-content {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.toggle-group-pagination .mat-button-toggle-label-content {
  padding: 0 10px !important;
}

@include devices(mobile, tablet) {
  :root .modal-dialog-wrap {
    padding: 0 10px;
  }

  .modal-dialog-wrap {
    overflow-x: hidden !important;
  }
}

.no-prod-icon {
  height: 30px !important;
  width: 30px !important;
}

.mat-mdc-tab-label-container {
  padding-top: 5px !important;
}

.multiline-tooltip {
  white-space: pre-line;
}
