//Icons
.mat-icon {
  &.icon-24 {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }

  &.icon-60 {
    font-size: 60px;
    height: 60px;
    width: 60px;
  }

  &.icon-72 {
    font-size: 72px;
    height: 72px;
    width: 72px;
  }

  &.icon-120 {
    font-size: 120px;
    height: 120px;
    width: 120px;
  }

  &.dark-grey-font {
    color: var(--dark-grey-font);
  }

  &.arrow-black {
    color: black;
  }

  &.green-icon {
    color: var(--green);
  }

  &.lilac-icon {
    color: var(--lilac);
  }

  //CHIPS

  &.chip-green {
    color: var(--green) !important;
  }

  &.chip-yellow {
    color: var(--yellow) !important;
  }

  &.chip-red {
    color: var(--red) !important;
  }
}

.light-blue {
  color: var(--light-blue);
}

.red {
  color: var(--red);
}

.blue-icon {
  color: var(--blue);
}

.gray-icon {
  color: var(--gray);
}

.avatar {
  width: 60px;
  height: 60px;
  border: solid 2px #fff;
  background-color: #000;
  border-radius: 50px;
  overflow: hidden;

  .initials {
    font-size: 35px;
    color: var(--white);
  }
}

//Badge
.mat-badge-content {
  background: var(--notifications);
}
